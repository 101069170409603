import { FormEvent } from "react";
import { RefetchOptions, QueryObserverResult } from "@tanstack/react-query";
import Button from "../../../../student/components/generic/button";

const AdminSearch: React.FC<{
  searchEmail: string;
  setSearchEmail: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  searchForAdminToAdd: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>;
}> = ({ searchEmail, setSearchEmail, disabled, searchForAdminToAdd }) => {
  //api call for searching for an admin not alreadt associated with the site

  const handleNewAdminSearch = (e: FormEvent) => {
    e.preventDefault();
    if (searchEmail?.length > 0) {
      searchForAdminToAdd();
    }
  };

  return (
    <div className="flex flex-row items-stretch gap-2">
      <form
        className="row flex items-stretch gap-2"
        onSubmit={handleNewAdminSearch}
      >
        <div className="self-center">Enter an email to search/add</div>

        <input
          className="min-w-[400px] rounded pl-2"
          value={searchEmail}
          disabled={disabled}
          onChange={(e) => setSearchEmail(e.target.value.toLowerCase())}
        />
        <Button submit={true}>Search</Button>
      </form>
    </div>
  );
};

export default AdminSearch;
