import { Fragment, FormEvent, useState, useEffect } from "react";
import { IAdminMutationData, AdminMutationOptionsType } from "./adminUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useActivationContext } from "../LicenseActivationContext";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import Button from "../../../../student/components/generic/button";
import { deltamathAPI } from "../../../utils";
import axios from "axios";
import {
  coerceLicenseType,
  discriminateInvoiceType,
} from "../../../utils/quoteUtils";

interface ICreateAdminProps {
  userDataForAdminMutation?: IAdminMutationData;
  showAddNewAdminForm: boolean;
  searchEmail: string;
  showAddExistingAdmin: boolean;
  updateAdmin: (params: any) => void;
  setMutationAction: React.Dispatch<
    React.SetStateAction<AdminMutationOptionsType>
  >;
  setRefetchInvoiceOnModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAdmin: React.FC<ICreateAdminProps> = ({
  userDataForAdminMutation,
  showAddNewAdminForm,
  searchEmail,
  showAddExistingAdmin,
  updateAdmin,
  setMutationAction,
  setRefetchInvoiceOnModalClose,
}) => {
  const activationContext = useActivationContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [newAdminPayload, setNewAdminPayload] = useState<{
    first: string;
    last: string;
  }>({ first: "", last: "" });

  const siteType = discriminateInvoiceType(
    coerceLicenseType(activationContext.invoiceDetails?.type || "")
  );

  //mutation for creating a "new" admin, whether actually new or taking an admin not associated w/ the site and adding them to the site
  const { mutate: createAdmin } = useMutation({
    mutationFn: async () => {
      const body = {
        siteType,
        siteId: activationContext.invoiceDetails?.id,
        userData: {
          firstName: showAddExistingAdmin
            ? userDataForAdminMutation?.first
            : newAdminPayload.first,
          lastName: showAddExistingAdmin
            ? userDataForAdminMutation?.last
            : newAdminPayload.last,
          email: showAddExistingAdmin
            ? userDataForAdminMutation?.email
            : searchEmail,
        },
        ...(activationContext.licenseData?.school?.status &&
        activationContext.licenseData?.school?.status !== "none"
          ? { integrations: activationContext.licenseData.school.integrations }
          : {}),
      };
      const response = await axios.post(
        deltamathAPI() +
          `/manager_new/invoices/create_admin/${activationContext.invoiceDetails?.quoteNumber}`,
        body
      );
      return response.data;
    },
    onSuccess: (res) => {
      toastContext.addToast({
        status: "Success",
        message: res.message || "Admin created successfully",
      });
    },
    onError: (e) => {
      toastContext.addToast({
        status: "Error",
        message:
          (e as any)?.response?.data?.message ||
          e.message ||
          "There was an error with the request: " + e,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["admins", activationContext.invoiceDetails?.quoteNumber],
      });
      queryClient.removeQueries({ queryKey: ["addAdmin"] });
      setRefetchInvoiceOnModalClose(true);
    },
  });

  const handleAdminCreate = (e: FormEvent) => {
    e.preventDefault();
    if (
      searchEmail.length > 0 &&
      newAdminPayload.first.length > 0 &&
      newAdminPayload.last.length > 0
    ) {
      createAdmin();
    } else {
      toastContext.addToast({
        status: "Error",
        message:
          "Please enter email, first name, and last name for the new admin.",
      });
    }
  };

  const handleCancel = () => {
    queryClient.removeQueries({ queryKey: ["addAdmin"] });
    setMutationAction("none");
  };

  let addExistingCopy = "";
  if (siteType === "school") {
    if (
      userDataForAdminMutation?.account_type === "super_school" ||
      userDataForAdminMutation?.account_type === "school"
    ) {
      addExistingCopy = `Making them the primary admin at this school will downgrade existing super_school admins to school admins, except that any super_school admin associated with multiple schools will not be downgraded.`;
    } else {
      addExistingCopy = `District level admins cannot be changed to super_school admins, but you can associate their account with this school.`;
    }
  } else if (siteType === "district") {
    addExistingCopy = `Changing their district association could have unintended
              consequences.`;
  }

  useEffect(() => {
    if (userDataForAdminMutation?.existingIsTeacherOnly) {
      setNewAdminPayload({
        first: userDataForAdminMutation?.first || "",
        last: userDataForAdminMutation?.last || "",
      });
    } else {
      setNewAdminPayload({ first: "", last: "" });
    }
  }, [userDataForAdminMutation?.existingIsTeacherOnly]);

  return (
    <Fragment>
      {showAddExistingAdmin && userDataForAdminMutation && (
        <div className="flex flex-row justify-between gap-2">
          {userDataForAdminMutation.email} already has a{" "}
          {userDataForAdminMutation.account_type} admin account -{" "}
          {userDataForAdminMutation.districtID
            ? ` district (${userDataForAdminMutation.districtID})`
            : ""}
          {userDataForAdminMutation.schoolIDs?.length
            ? ` schools (${userDataForAdminMutation.schoolIDs.join(", ")}).`
            : ""}{" "}
          {addExistingCopy}
          <div className="flex grow flex-row items-center gap-2">
            {siteType === "school" && (
              <>
                {(userDataForAdminMutation?.account_type === "school" ||
                  userDataForAdminMutation?.account_type ===
                    "super_school") && (
                  <Button
                    className="text-xs"
                    onClick={createAdmin}
                    disabled={showAddNewAdminForm}
                  >
                    Primary
                  </Button>
                )}
                <Button
                  className="text-xs"
                  onClick={() =>
                    updateAdmin({
                      adminPayload: {
                        newAdminID: userDataForAdminMutation._id,
                        newAdminEmail: userDataForAdminMutation.email,
                        newAdminFirst: userDataForAdminMutation.first,
                        newAdminLast: userDataForAdminMutation.last,
                      },
                      addSchoolOnly: true,
                    })
                  }
                >
                  School
                </Button>
              </>
            )}
            <Button
              onClick={handleCancel}
              className="bg-red-500 text-xs hover:bg-red-700"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {showAddNewAdminForm && (
        <div className={"flex w-full flex-col gap-4"}>
          <div className="self-center">
            There is no admin account with the email {searchEmail} in our system
            {userDataForAdminMutation?.existingIsTeacherOnly &&
              " but there is an existing teacher account with this email. The name of the user has been filled into the form below. Creating the admin account will automatically associate the teacher and admin accounts together"}
            . To create, enter further details below and submit. Creating a
            completely new admin will NOT automatically downgrade any existing
            admins.
          </div>
          <div className="w-full">
            <form
              className="flex w-full flex-row justify-center gap-4"
              onSubmit={handleAdminCreate}
            >
              <div className="flex flex-row items-center gap-2">
                <label htmlFor="firstName">First Name:</label>
                <input
                  className="rounded pl-2"
                  type="text"
                  id="firstName"
                  value={newAdminPayload.first}
                  onChange={(e) =>
                    setNewAdminPayload((prev) => ({
                      ...prev,
                      first: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <label htmlFor="lastName">Last Name:</label>
                <input
                  className="rounded pl-2"
                  type="text"
                  id="lastName"
                  value={newAdminPayload.last}
                  onChange={(e) =>
                    setNewAdminPayload((prev) => ({
                      ...prev,
                      last: e.target.value,
                    }))
                  }
                />
              </div>
              <Button submit={true}>Create</Button>
              <Button
                onClick={handleCancel}
                submit={false}
                className="bg-red-500 text-xs hover:bg-red-700"
              >
                Cancel
              </Button>
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CreateAdmin;
