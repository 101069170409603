import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { deltamathAPI } from "../../../utils";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { useActivationContext } from "../LicenseActivationContext";
import {
  AdminMutationOptionsType,
  IAdminMutationData,
  IAdminData,
} from "./adminUtils";
import AdminTable from "./AdminTable";
import AdminSearch from "./AdminSearch";
import AdminMutations from "./AdminMutations";
import Button from "../../../../student/components/generic/button";

const ChangePrimaryAdmins: React.FC<{
  admins: IAdminData[];
  setRefetchInvoiceOnModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ admins, setRefetchInvoiceOnModalClose }) => {
  const activationContext = useActivationContext();

  const toastContext = useDeltaToastContext();
  //tracks whether to give the option to upgrade admin aready associated with the site to super admin
  const [cannotDowngrade, setCannotDowngrade] = useState("");
  //data needed for mutations involving admins (whether or not associated with the site)
  const [userDataForAdminMutation, setUserDataForAdminMutation] =
    useState<IAdminMutationData>({});

  //tracks email entered into search bar, which could end up as part of the payload to associate a new admin with the site
  const [searchEmail, setSearchEmail] = useState<string>("");

  const [mutationAction, setMutationAction] =
    useState<AdminMutationOptionsType>("none");

  const [studentAccount, setStudentAccount] = useState<{
    _id?: string;
    login?: string;
  }>({});

  //search for an admin not already associated with the site
  const { data: adminToAddData, refetch: searchAdminToAdd } = useQuery({
    queryFn: async () => {
      const response = await axios.get(
        deltamathAPI() + `/manager_new/invoices/admin_exists/${searchEmail}`
      );

      return response.data as {
        exists: boolean;
        admin?: IAdminData;
        student?: { _id: string; login: string };
        teacher?: { email: string; first: string; last: string };
      };
    },
    queryKey: ["addAdmin"],
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (adminToAddData?.exists === false) {
      setMutationAction("addNewAdminToSite");
    } else if (
      adminToAddData?.admin?.email &&
      adminToAddData?.admin?.first &&
      adminToAddData.admin?.last
    ) {
      setMutationAction("addExistingAdminToSite");
      setUserDataForAdminMutation(adminToAddData.admin);
    } else if (adminToAddData?.teacher && adminToAddData?.teacher.email) {
      setMutationAction("addNewAdminToSite");
      setUserDataForAdminMutation({
        email: adminToAddData.teacher.email,
        first: adminToAddData.teacher.first,
        last: adminToAddData.teacher.last,
        existingIsTeacherOnly: true,
      });
    } else if (adminToAddData?.student) {
      setStudentAccount(adminToAddData?.student);
    } else if (adminToAddData?.exists === true) {
      toastContext.addToast({
        status: "Error",
        message: "Admin exists but something is wrong with data returned",
      });
      setMutationAction("none");
    } else {
      setUserDataForAdminMutation({});
    }
  }, [adminToAddData]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      {admins && admins.length > 0 && (
        <div className="flex flex-col gap-2 rounded-md">
          <AdminTable
            admins={admins}
            userDataForAdminMutation={userDataForAdminMutation}
            setUserDataForAdminMutation={setUserDataForAdminMutation}
            disableInput={
              (mutationAction && mutationAction !== "none") ||
              cannotDowngrade !== ""
            }
            setCannotDowngrade={setCannotDowngrade}
            setMutationAction={setMutationAction}
          />
        </div>
      )}
      <AdminSearch
        searchEmail={searchEmail}
        setSearchEmail={setSearchEmail}
        disabled={
          mutationAction === "addNewAdminToSite" ||
          mutationAction === "addExistingAdminToSite"
        }
        searchForAdminToAdd={searchAdminToAdd}
      />
      <div className="flex w-full flex-col items-center">
        {cannotDowngrade && (
          <div className={"flex w-full flex-row justify-center gap-4"}>
            <div className="self-center">{cannotDowngrade}</div>
            <Button
              onClick={() => {
                setCannotDowngrade("");
                setMutationAction("none");
                setUserDataForAdminMutation({});
              }}
            >
              OK
            </Button>
          </div>
        )}
      </div>
      {mutationAction && mutationAction !== "none" && (
        <AdminMutations
          admins={admins}
          userDataForAdminMutation={userDataForAdminMutation}
          setUserDataForAdminMutation={setUserDataForAdminMutation}
          mutationAction={mutationAction}
          setMutationAction={setMutationAction}
          searchEmail={searchEmail}
          setRefetchInvoiceOnModalClose={setRefetchInvoiceOnModalClose}
        />
      )}
      {studentAccount && studentAccount._id && (
        <div className="flex w-full items-center justify-center gap-4">
          <p>
            Email is already associated with a student account{" "}
            {studentAccount.login}{" "}
          </p>
          <Button
            onClick={() => {
              setStudentAccount({});
            }}
          >
            Got it
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChangePrimaryAdmins;
