import { useDocumentTitle } from "usehooks-ts";
import { DataTableContextProvider } from "../DataTable/DataTableContext";
import { DataTable } from "../DataTable/DataTable";
import { RenewalsSummary } from "./RenewalsSummary";

export const RenewalsPage: React.FC = () => {
  useDocumentTitle("Renewals");

  return (
    <main className="flex flex-col gap-6 p-6">
      <h1 className="text-2xl font-bold">Renewals</h1>
      <RenewalsSummary />
      <DataTableContextProvider
        source="/manager_new/renewals/all/new"
        sortable={[]}
        defaults={{ sortBy: [{ id: "afdsjk", desc: true }] }}
        fetchOnLoad
      >
        <DataTable />
      </DataTableContextProvider>
    </main>
  );
};
